<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${urlName}listPage?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
              <!-- <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">公司概况</div>
              <div class="fr listHdGrop"><a :href="urlName">首页</a> · <a :href="`${urlName}/listPage`">公司概况</a> · <span class="txt">{{hdName}}</span> </div>
            </div>
            <div class="listContainer" v-if="pageType">
              <list-cont-vue ref="listContVue" v-if="pageType == 1" :listId="listId"></list-cont-vue>
              <list-pic-vue ref="listPicVue" v-if="pageType == 2" :listId="listId"></list-pic-vue>
              <list-detail-vue ref="listDetailVue" v-if="pageType == 3" :listId="listId"></list-detail-vue>
              <sub-organication ref="subOrganication" v-if="pageType == 4" :listId="listId"></sub-organication>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import listContVue from '../components/listCont.vue'
import listDetailVue from '../components/listDetail.vue'
import listPicVue from '../components/listPic.vue'
import subOrganication from '../components/subOrganication.vue'
export default {
  name: 'list',
  components: {
    listPicVue,
    listContVue,
    listDetailVue,
    subOrganication
  },
  data(){
    return {
      listId: null,
      pageType: null, // 1:列表；2：图片列表；3：详情；4：组织机构
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '公司概况',
        sub: 'COMPANY',
      },
      slideList:[
        {
          title: '公司简介',
          id: '1'
        },
        {
          title: '领导成员',
          id: '2'
        },
        {
          title: '组织机构',
          id: '3'
        },
        {
          title: '资质荣誉',
          id: '4'
        },
        {
          title: '大事记',
          id: '5'
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: [],
      page: []
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }
    
    _this.getSideNav()
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    },
    // 获取二级栏目
    async getSideNav() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: "143"});
      _this.slideList = data.data
      _this.hdTitle = _this.slideList[_this.active].name
      _this.hdName = _this.slideList[_this.active].name
      _this.listId = _this.slideList[_this.active].id
      if (_this.slideList[_this.active].have_list == 1 && _this.slideList[_this.active].id !== 439) {
        _this.pageType = 3
      } else if (_this.slideList[_this.active].have_list == 1 && _this.slideList[_this.active].id == 439) {
        _this.pageType = 4
      } else if (_this.slideList[_this.active].have_list == 0 && _this.slideList[_this.active].id == 440) {
        _this.pageType = 2
      } else {
        _this.pageType = 1
      }
      // _this.getList(_this.slideList[_this.active].id)
    },
    // 查列表
    async getList(id) {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: id, page: 1, pageSize: 10});
      _this.getDetail(data.data.list[0].id)
    },
    // 查详情
    async getDetail(id) {
      var _this = this;

      _this.loading = true
      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.pageCont = data.data.content;
      _this.loading = false
    },
  }
}
</script>